const style = {
  container: {
    height: "calc(100vh - 64px)",
    padding: '24px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
};

export default style;