import { callStatusEvents } from "@common/constants";
import { noop } from "@client/shared/constants";
import CallRecorderView from "./view";

const CallRecorder = ({
  callStatus = callStatusEvents.start,
  shadow = 1,
  name = "-",
  phoneNumber = "-",
  recordingStatus = "",
  errorMessage = undefined,
  isLoading = false,
  isActionLoading = false,
  rootStyle = {},
  isMobile = false,
  actionDisabled = false,
  onPlay = noop,
  onPause = noop,
  onStop = noop,
  onDelete = noop
}) => {
  return (
    <CallRecorderView
      shadow={shadow}
      name={name}
      phoneNumber={phoneNumber}
      errorMessage={errorMessage}
      recordingStatus={recordingStatus}
      isLoading={isLoading}
      isActionLoading={isActionLoading}
      rootStyle={rootStyle}
      isMobile={isMobile}
      actionDisabled={actionDisabled}
      callStatus={callStatus}
      onPlay={onPlay}
      onPause={onPause}
      onStop={onStop}
      onDelete={onDelete}
    />
  )
};

export default CallRecorder;