import { callStatusEvents, recordingStatusReturned } from "@common/constants";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { noop } from "@client/shared/constants";

import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import Loader from "./loader";
import DeleteButton from "./DeleteButton";
import { Divider } from "@mui/material";

const CANNOT_RECORD_LABEL = "Recording is not available for this call. We apologize for any inconvenience. If this call must be recorded, please make a new call to the client"

const RECORDING_STATUS_LABEL = {
  [recordingStatusReturned.recording]: "RECORDING",
  [recordingStatusReturned.paused]: "PAUSED",
  [recordingStatusReturned.stopped]: "STOPPED",
  [recordingStatusReturned.unknown]: "UNAVAILABLE",
  "": "UNAVAILABLE"
};

const CallRecorderView = ({
  callStatus = callStatusEvents.start,
  shadow = 2,
  name = "-",
  phoneNumber = "-",
  errorMessage = undefined,
  recordingStatus = "",
  isLoading = false,
  isActionLoading = false,
  rootStyle = {},
  isMobile = false,
  actionDisabled = false,
  onPlay = noop,
  onPause = noop,
  onStop = noop,
  onDelete = noop,
}) => {
  const renderRecordingLabel = (
    recordingStatus = "",
    isActionLoading = false,
    callStatus = callStatusEvents.start,
    isUnknown = false
  ) => {
    let element = <></>;
    if (callStatus === callStatusEvents.hold) {
      element = (
        <Typography variant="body1" sx={{ mt: 1, width: "92px" }}>
          Call On Hold
        </Typography>
      );
    // } else if (isActionLoading) {
    //   element = <Skeleton variant="text" width={148} sx={{ mt: 1 }} />;
    } else if (
      recordingStatus === recordingStatusReturned.recording ||
      recordingStatus === recordingStatusReturned.paused
    ) {
      element = (
        <Chip
          color={
            recordingStatus === recordingStatusReturned.recording
              ? "error"
              : "warning"
          }
          label={
            <Typography variant="body1">
              {RECORDING_STATUS_LABEL[recordingStatus]}
            </Typography>
          }
        />
      );
    } else if (recordingStatus === recordingStatusReturned.stopped) {
      element = (
        <Typography variant="body1" sx={{ mt: 1 }}>
          {RECORDING_STATUS_LABEL[recordingStatus]}
        </Typography>
      );
    }
    else if (recordingStatus !== "initial" && isUnknown) {
      element = (
        <Tooltip placement='top' placeholder={CANNOT_RECORD_LABEL}>
          <Typography variant="body1" sx={{ mt: 1 }}>
            UNKNOWN
          </Typography>
        </Tooltip>
      );
    }

    return element;
  };

  let isUnknown = !Object.values(recordingStatusReturned).includes(
    recordingStatus
  );

  if (isLoading) {
    return <Loader shadow={shadow} rootStyle={rootStyle} />;
  }

  return (
    <Paper
      elevation={shadow}
      sx={{
        p: 8,
        display: "flex",
        alignItems: "center",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "space-between",
        borderRadius: 2,
        ...rootStyle,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
          flex: 1,
          minWidth: 0,
        }}
      >
        <Tooltip placement="top-start" title={name}>
          <Typography
            noWrap
            variant="h4"
            sx={{ fontFamily: "proxima_novasemibold", textAlign: isMobile ? 'center' : 'initial' }}
            width={isMobile ? 248 : 'auto'}

          >
            {name}
          </Typography>
        </Tooltip>
        <Chip
          label={
            <Typography noWrap variant="body1">
              {phoneNumber}
            </Typography>
          }
          sx={{ ml: 4 }}
          onClick={() => console.log({phoneNumber, name, callStatus, isLoading, isActionLoading})}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        {/* {isActionLoading ? (
          [...new Array(3)].map((_, index) => (
            <IconButton key={index} disabled>
              <Skeleton variant="circular" width={36} height={36} />
            </IconButton>
          ))
        ) : ( */}
          {errorMessage && errorMessage !== '' ? <p style={{maxWidth: 400, color: errorMessage.includes('whitelisted') ? 'red' : 'black'}}>{errorMessage}</p> : null}
          <div style={{ display: `${errorMessage && errorMessage !== '' ? 'none' : 'flex'}` }}>
            <Tooltip placement="top-start" title={ isUnknown ? CANNOT_RECORD_LABEL : recordingStatus === recordingStatusReturned.paused ? "Resume" : "Start"}>
              <Box>
                <IconButton
                  disabled={
                    recordingStatus === recordingStatusReturned.recording ||
                    actionDisabled || isUnknown || isActionLoading
                  }
                  onClick={onPlay}
                >
                  <PlayCircleFilledWhiteOutlinedIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Box>
            </Tooltip>
              <Tooltip placement="top-start" title={isUnknown ? CANNOT_RECORD_LABEL : 'Pause'}>
            <Box>
                <IconButton
                  disabled={
                    recordingStatus === recordingStatusReturned.stopped ||
                    recordingStatus === recordingStatusReturned.paused ||
                    actionDisabled ||
                    isUnknown || isActionLoading
                  }
                  onClick={onPause}
                >
                  <PauseCircleOutlinedIcon sx={{ fontSize: 40 }} />
                </IconButton>
            </Box>
              </Tooltip>
            <Tooltip placement="top-start" title={isUnknown ? CANNOT_RECORD_LABEL : 'Stop'}>
              <Box>
                <IconButton
                  disabled={
                    recordingStatus === recordingStatusReturned.stopped ||
                    isUnknown || isActionLoading
                  }
                  onClick={onStop}
                >
                  <StopCircleOutlinedIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Box>
            </Tooltip>
            {isUnknown ? <Divider orientation="vertical" flexItem /> : null}
            {isUnknown ? <DeleteButton onDelete={onDelete} /> : null}
          </div>
        {/* )} */}
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translate(-50%, 125%)",
          }}
        >
          {renderRecordingLabel(recordingStatus, isActionLoading, callStatus, isUnknown)}
        </Box>
      </Box>
    </Paper>
  );
};

export default CallRecorderView;
