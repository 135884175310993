import { globalStyles } from "@client/shared/constants";
const { display, flex } = globalStyles;

const style = {
  icon: {
    color: "black",
    ...flex.justify.end,
  },
};

export default style;
