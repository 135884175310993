import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';
import Popover from "@mui/material/Popover";
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Autocomplete from '@mui/material/Autocomplete';
import  Skeleton  from "@mui/material/Skeleton";
import { noop } from "@client/shared/constants";

const CustomSwicth = styled(Switch)(() => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#2470AC',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const defaultState = {
  anchorEl: null,
  hours: 1,
  minutes: 0,
  meridian: 'am'
};

const TimePicker = ({
  size = 'small',
  fullWidth = true,
  rootSX = {},
  hours = 1,
  minutes = 0,
  meridian = 'am',
  disabled = false,
  isLoading = false,
  mode = "input",
  onTimeChange = noop
}) => {

  const [state, setState] = useState(defaultState);

  const handleOpen = event => {
    setState(prevState => ({
      ...prevState,
      anchorEl: event.currentTarget,
      meridian,
      hours,
      minutes,
    }));
  };

  const handleClose = () => {
    setState(prevState => {
      onTimeChange({
        hours: prevState.hours,
        minutes: prevState.minutes,
        meridian: prevState.meridian,
      });
      return {
        ...prevState,
        anchorEl: null
      }
    });
  };

  const handleHourChange = (event, isInput = true) => {
    let hours = parseInt(isInput ? event.target.value : event);
    hours = isNaN(hours) ? 1 : hours;
    if (hours < 13 && hours > 0) {
      setState(prevState => ({
        ...prevState,
        hours,
      }));
    }
  };

  const handleMinuteChange = (event, isInput = true) => {
    let minutes = parseInt(isInput ? event.target.value : event);
    minutes = isNaN(minutes) ? 0 : minutes;
    if (minutes < 60 && minutes > -1) {
      setState(prevState => ({
        ...prevState,
        minutes,
      }));
    }
  };

  const handleMeridianChange = (meridian) => {
    setState(prevState => ({
      ...prevState,
      meridian
    }))
  };

  const getPickerLabel = () => {
    const hours = String(state.hours).padStart(2, '0');
    const minutes = String(state.minutes).padStart(2, '0')

    return `${hours}:${minutes} ${(state.meridian || '').toUpperCase()}`;
  };

  const open = Boolean(state.anchorEl);

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      hours,
      minutes,
      meridian
    }));

    return () => {
      setState(prevState => ({
        ...prevState,
        ...defaultState
      }));
    }
  }, [
    hours,
    minutes,
    meridian
  ]);

  return (
    <>
      {isLoading ? (
        <Skeleton
          height={64}
          width={248}
          sx={rootSX}
        />
      ) : (
        <TextField
          disabled={disabled}
          value={getPickerLabel()}
          fullWidth={fullWidth}
          size={size}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end" >
                <AccessTimeIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          onClick={disabled ? noop : handleOpen}
          sx={rootSX}
        />
      )}
      <Popover
        open={open}
        anchorEl={state.anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mr: 4 }}>
             {mode === "input" &&  (
             <>  
               <IconButton
              disabled={state.hours > 11}
              size="small"
              onClick={() => handleHourChange(state.hours + 1, false)}
            >
              <ArrowDropUpIcon color={state.hours > 11 ? "grey.700" : "primary"} />
            </IconButton> 
             <TextField
              value={state.hours}
              size="small"
              sx={{ width: 96, my: 1 }}
              onChange={handleHourChange}
            /> 
             <IconButton
              disabled={state.hours < 2}
              size="small"
              onClick={() => handleHourChange(state.hours - 1, false)}
            >
              <ArrowDropDownIcon color={state.hours < 2 ? "grey.700" : "primary"} />
              </IconButton> 
            </>
            )} 
             {mode === "dropdown" && (
              <Autocomplete
              disableClearable
              size="small"
              id="combo-box-demo"
              options={[...(new Array(12))].map((_,index)=>({
                label:String(index+1).padStart(2,"0"),
                value: index+1
              }))}
              sx={{ width: 96,marginTop:"8px" }}
              renderInput={(params) => <TextField {...params} label="Hour" />}
              value={String(state.hours).padStart(2,"0")}
              onChange={(_,value)=> handleHourChange({
                target: {
                    name: '',
                    value:value?.value
              }
            })}
            />
             )}
            
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">:</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ml: 4, }}>
           {mode==="input" && (
           <> 
            <IconButton
              disabled={state.minutes > 58}
              size="small"
              onClick={() => handleMinuteChange(state.minutes + 1, false)}
            >
              <ArrowDropUpIcon color={state.minutes > 58 ? "grey.700" : "primary"} />
            </IconButton>
            <TextField
              value={state.minutes}
              size="small"
              sx={{ width: 96, my: 1 }}
              onChange={handleMinuteChange}
            />
            <IconButton
              disabled={state.minutes < 1}
              size="small"
              onClick={() => handleMinuteChange(state.minutes - 1, false)}
            >
              <ArrowDropDownIcon color={state.minutes < 1 ? "grey.700" : "primary"} />
            </IconButton>
            </>
            )}
            {mode==="dropdown" && (
              <Autocomplete
              disableClearable
              size="small"
              id="combo-box-demo"
              options={[0,15,30,45].map((item)=>({
                label:String(item).padStart(2,"0"),
                value: item
              }))}
              sx={{ width: 96, marginTop:"8px" }}
              value={String(state.minutes).padStart(2,"0")}
              renderInput={(params) => <TextField {...params} label="Min" />}
              onChange={(_,value)=> handleMinuteChange({
                target: {
                    name: '',
                   value:value?.value
              }
            })}
            />
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 8 }}>
            <FormControlLabel
              sx={{ minWidth: '96px' }}
              label={(
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {(state.meridian || '').toLocaleUpperCase()}
                </Typography>
              )}
              control={(
                <CustomSwicth
                  sx={{ m: 1 }}
                  checked={state.meridian === 'pm'}
                  onChange={evt => handleMeridianChange(evt.target.checked ? 'pm' : 'am')}
                />
              )}
            />
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default TimePicker;