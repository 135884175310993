export const socketEvent = {
  currentActiveCalls: "current-active-calls",
  controlRecording: "control-recording",
  recordingStatus: "recording-status",
  error: "error",
  disconnect: "disconnect",
  callStatus: "call-status",
};

export const callStatusEvents = {
  start: "start",
  end: "end",
  hold: "hold",
};

export const recordingEvents = {
  start: "start",
  pause: "pause",
  stop: "stop",
  resume: "resume",
  initial: "initial",
  delete: 'delete'
};

export const recordingStatusReturned = {
  recording: "recording",
  paused: "paused",
  stopped: "stopped",
  unknown: "unknown",
};

export const errorActions = {
  updateRecording: "update-recording",
  mappingNotFoundOnRC: "ext-ntid-mapping",
};

export const commonErrorCodes = {
  mappingNotFound: {
    code: "US-001",
    field: "ntId",
    message: "Ntid extension mapping not found on RC platform",
  },
};

export const recordingEventsMapping = {
  start: "recording",
  pause: "paused",
  stop: "stopped",
  resume: "recording",
};
