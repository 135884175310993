import { CallRecorder } from "@client/shared/components";
import { noop } from "@client/shared/constants";
import { callStatusEvents, recordingEvents, recordingStatusReturned } from "@common/constants";
import Alert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';
import style from './style';
import { useCallback } from "react";
import { debounce, internalLog } from "@client/utils";

const DEBOUNCE_THRESHOLD = 250

const HomeView = ({
  isActiveCallFetched = false,
  isMappingNotFoundOnRC = false,
  isLoading = false,
  activeCalls = [],
  handleRecordingActions = noop,
  handleDelete = noop,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const debouncedRecordingAction = useCallback((event, item) => {
    debounce(() => {
      handleRecordingActions(event, item)
    }, DEBOUNCE_THRESHOLD)
  }, [handleRecordingActions])

  if (isLoading) {
    return (
      <Box sx={style.container}>
        {new Array(5).fill("").map((_, index) => (
          <CallRecorder
            key={index}
            isLoading
            rootStyle={{ mb: 8, width: (isTablet || isMobile) ? '100%' : '50%' }}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box sx={style.container}>
      {((isActiveCallFetched && !activeCalls.length) || isMappingNotFoundOnRC) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '84px'
          }}
        >
          {isMappingNotFoundOnRC ? (
            <Alert
              variant="outlined"
              severity="error"
              sx={{
                '& .MuiAlert-icon': {
                  alignItems: 'center'
                }
              }}
            >
              <Typography variant="h6" sx={{ fontFamily: 'proxima_novasemibold' }}>
                No corresponding extension found in RingCentral for current user.
              </Typography>
            </Alert>
          ) : (
            <>
              <Typography variant="h3" sx={{ fontFamily: 'proxima_novasemibold' }}>No active calls</Typography>
              <Typography variant="h6" sx={{ mt: 10 }}>ACTIVE CALLS WILL APPEAR HERE</Typography>
            </>
          )}
        </Box>
      )}
      {!isMappingNotFoundOnRC && (
        <Box sx={{ width: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {activeCalls.map((item, index) => (
          <CallRecorder
          key={index}
          actionDisabled={item.callStatus === callStatusEvents.hold}
          callStatus={item.callStatus}
          isActionLoading={item.isRecordingStatusUpdating}
          name={item.name}
          phoneNumber={item.phoneNumber}
          recordingStatus={item.recordingStatus}
          errorMessage={item.errorMessage}
          rootStyle={{ mb: 8, width: (isTablet || isMobile) ? '100%' : '50%' }}
          isMobile={isMobile}
          onPlay={() => handleRecordingActions(item.recordingStatus === recordingStatusReturned.paused ? recordingEvents.resume : recordingEvents.start, item)}
          onPause={() => handleRecordingActions(recordingEvents.pause, item)}
          onStop={() => handleRecordingActions(recordingEvents.stop, item)}
          onDelete={() => handleDelete(item)}
        />
          ))}
        </Box>
      )}
      {(!isMappingNotFoundOnRC && !!activeCalls.length) && (
        <Box sx={{ width: (isTablet || isMobile) ? '100%' : '50%' }}>
          <Typography variant="body1" sx={{ fontFamily: 'proxima_novasemibold' }}>
            Disclaimer
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, fontFamily: 'proxima_novasemibold' }}>
            Please note: all Outbound and Inbound Live Lead transfer calls require you to disclose to the caller that recording is in progress. For example: “Before we proceed, I wanted to let you know that you are on a recorded line” or “Before we proceed, this line is being recorded.”
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, fontFamily: 'proxima_novasemibold' }}>
            Please stop or pause the recording using the buttons when discussing information related to credit, debit, or other payment cards, or any protected health information during a call.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, fontFamily: 'proxima_novasemibold' }}>
            You can use the start or resume button when you are no longer discussing any sensitive payment or health information to resume recording.
          </Typography>
        </Box>
      )}
    </Box>
  )
};

export default HomeView;