import { useCallback } from "react";
import config from "@client/config";
import LoginView from "./view";

const Login = () => {
  
  const handleLogin = useCallback(() => {
    if (typeof window !== "undefined") {
      window.location.replace(`${config.apiBaseUrl}login`);
    }
  }, []);

  return (
    <LoginView
      handleLogin={handleLogin}
    />
  )
};

export default Login;