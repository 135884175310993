import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

const Loader = ({
  shadow = 2,
  rootStyle = {}
}) => {
  return (
    <Paper
      elevation={shadow}
      sx={{
        p: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 2,
        ...rootStyle
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography noWrap variant="h4">
          <Skeleton width={148} />
        </Typography>
        <Typography noWrap variant="h4">
            <Skeleton width={96} sx={{ ml: 4 }} />
        </Typography>
      </Box>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        {[0, 4, 0].map((marginX, index) => (
          <Skeleton key={index} variant="circular" width={36} height={36} sx={{ mx: marginX }}/>
        ))}
      </Box>
    </Paper>
  )
};

export default Loader;