import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { noop } from "@client/shared/constants";

const LoginView = ({
  handleLogin = noop
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Paper
      sx={{
        width: (isTablet || isMobile) ? '100%' : '50%',
        margin: '0 auto',
        marginTop: '96px',
        padding: '96px',
      }}
    >
      <Typography sx={{ fontFamily: 'proxima_novasemibold' }} variant="h4">Sign In</Typography>
      <Button
        fullWidth
        size="large"
        variant="contained"
        startIcon={<ArrowBackIosIcon />}
        sx={{ mt: 5, fontSize: '24px', fontFamily: 'proxima_novasemibold' }}
        onClick={handleLogin}
      >Login</Button>
    </Paper>
  )
};

export default LoginView;