import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip } from '@mui/material';

const DeleteButton = ({onDelete}) => {
    return (
        <Tooltip placement="top-start" title='Hide this call from view'>
            <Box>
                <IconButton
                    onClick={onDelete}
                >
                    <DeleteIcon sx={{ fontSize: 40 }} />
                </IconButton>
            </Box>
        </Tooltip>
    )
}

export default DeleteButton