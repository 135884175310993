import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AppService } from "@client/shared/services";
import { globalStyles } from "@client/shared/constants";
import { About } from "@client/shared/components";

import style from "./style";
import { toast } from "react-toastify";

const defaultState = {
  open: false,
};

export default function Header({
  elements = [],
  position = "fixed",
  userData = {},
  version = {},
  showProfile = true
}) {
  const [state, setState] = useState(defaultState);
  const { profileImageUri = "", ntId = "" } = userData;

  const handleDrawerClose = () => {
    setState((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  const handleLogout = async () => {
    const { error } = await AppService.logoutUser();
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error);
    } else {
      typeof window !== "undefined" && window.location.replace(`/`);
    }
  };

  return (
    <AppBar
      position={position}
      elevation={2}
      sx={{ background: '#fff' }}
    >
      <Toolbar
        sx={
          !!elements
            ? globalStyles.flex.justify.between
            : globalStyles.flex.justify.end
        }
      >
        {elements}
        {showProfile && (
          <>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={profileImageUri || ' '}
                  alt={ntId}
                  sx={globalStyles.cursor.pointer}
                  onClick={() => {
                    setState((prevState) => ({
                      ...prevState,
                      open: !prevState.open,
                    }));
                  }}
                />
              </Box>
            </Box>
            <Drawer open={state.open} onClose={handleDrawerClose} anchor="right">
              <Box sx={style.drawerPaper}>
                <Box sx={style.nameWrapper}>
                  <Avatar
                    src={profileImageUri || ' '}
                    alt={ntId}
                  />
                  <Box sx={style.userData}>
                    <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                      {ntId}
                    </Typography>
                  </Box>
                </Box>
                <MenuList>
                  <About version={version} />
                  <MenuItem onClick={handleLogout}>
                    <ListItemText primary="Sign Out" />
                    <ListItemIcon sx={style.icon}>
                      <ArrowForwardIcon fontSize="small" />
                    </ListItemIcon>
                  </MenuItem>
                </MenuList>
              </Box>
            </Drawer>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
