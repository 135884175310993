import React from "react";
import Box from "@mui/material/Box";
import Header from "../../header";
import Typography from "@mui/material/Typography";

const SidebarHeader = ({
  userData = {},
  version = {},
  pageTitle = "",
  children = <></>,
  showProfile = true
}) => {
  return (
    <Box>
      <Header
        userData={userData}
        version={version}
        elements={
          <Box sx={{ display: 'flex'}}>
            <Typography variant="h5" sx={{ color: '#000', fontFamily: 'proxima_novasemibold' }}>{pageTitle}</Typography>
          </Box>
        }
        showProfile={showProfile}
      />
      {children}
    </Box>
  );
};

export default SidebarHeader;