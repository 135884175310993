import { useCallback, useContext, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DefaultLayout } from "@client/shared/components";
import { AppService } from "@client/shared/services";
import initSocket, { registerHandlers } from "@client/shared/services/socket";
import Box from "@mui/material/Box";
import { AppContext } from "@client/shared/contexts";
import Home from "./home";
import Login from "./login";
import { commonErrorCodes } from "@common/constants";

const App = () => {
  const { setAppData, appData } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPage = location.pathname === '/';

  const fetchUserData = useCallback(async () => {
    const { data, error } = await AppService.getUserDetails();
    if (error) {
      !isLoginPage && toast.error(Array.isArray(error) ? error[0]?.message : error)
    }
    return {
      userData: data?.user || {},
      error
    };
  }, [isLoginPage]);

  const fetchVersion = useCallback(async () => {
    const { data, error } = await AppService.getVersion();
    if (error) {
      toast.error(Array.isArray(error) ? error[0]?.message : error)
    }
    return error ? {} : data || {};
  }, []);

  const initializeApp = useCallback(async () => {
    const { userData, error } = await fetchUserData();
    const isMappingNotFoundOnRC = error?.code === commonErrorCodes.mappingNotFound.code;
    if (isLoginPage) {
      (userData.ntId || isMappingNotFoundOnRC) && navigate('/home')
    } else {
      const version = await fetchVersion();
      if (!isMappingNotFoundOnRC) {
        await initSocket();
        registerHandlers({
          connect: () => setAppData("isSocketConnected", true),
          connect_error: () => setAppData("isSocketConnected", false),
          disconnect: () => setAppData("isSocketConnected", false),
        });
      }
      setAppData("userData", userData);
      setAppData("version", version);
    }
    setAppData("isMappingNotFoundOnRC", isMappingNotFoundOnRC);
  }, [
    fetchUserData,
    fetchVersion,
    isLoginPage
  ]);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  return (
    <DefaultLayout
      userData={appData.userData || {}}
      version={appData.version || {}}
      pageTitle="Call Recording Manager"
      showProfile={!isLoginPage}
    >
      <Box sx={{ mt: "64px", mx: 8 }}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path='/home' element={<Home />} />
        </Routes>
      </Box>
    </DefaultLayout>
  );
};

export default App;
