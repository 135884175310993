export { default as responseFormatter } from "./response-formatter";

export const RC_TEST_NTID = 'tst-ringcentral'

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export function showBlockingLoader() {
  const loaderElement = document.getElementById("blocker-page-loader");
  if (loaderElement) {
    loaderElement.style.zIndex = 99999;
    loaderElement.style.display = "flex";
  }
}

export function hideBlockingLoader() {
  const loaderElement = document.getElementById("blocker-page-loader");
  if (loaderElement) {
    loaderElement.style.zIndex = -10;
    loaderElement.style.display = "none";
  }
}
export const SUPPORTED_FILE_FORMATS = {
  FEEDBACK: [
    "mov",
    "avi",
    "mp4",
    "mkv",
    "webm",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "ogg",
    "m4p",
    "m4v",
    "wmv",
    "qt",
    "flv",
    "swf",
    "avchd",
    "jpg",
    "jpeg",
    "png",
    "gif",
    "xlsx",
    "csv",
    "pdf",
  ],
};

export const getFileExtension = (name) => {
  const lastDot = name.lastIndexOf(".");
  const extension = name.substring(lastDot + 1);
  return extension;
};

export const getFormattedDate = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${monthNames[monthIndex]} ${day}, ${year}`;
};

export const getMonthList = (options = { month: "long" }, locale = "en") => {
  return Array.from({ length: 12 }, (item, index) => {
    return new Date(null, index + 1, null).toLocaleDateString(locale, options);
  });
};

export const updatePagination = ({ count, pageNumber, pageSize, callback }) => {
  if (typeof count === "undefined") return;
  if (pageNumber !== 1 && count <= pageSize * (pageNumber - 1)) {
    callback(pageNumber - 1);
  }
};

const filterComparator = (value = '', searchValue = '', operatorType) => {
  let found = false;
  switch (operatorType) {
    case 'iRegexp':
      // found = searchValue.indexOf(value) !== -1;
      found = value.indexOf(searchValue) !== -1;
      break;
    case 'iLike':
      // found = value.includes(searchValue);
      found = searchValue == value;
      break;
    case 'notIRegexp':
      // found = searchValue.indexOf(value) === -1;
      found = value.indexOf(searchValue) === -1;
      break;
    case 'notILike':
      // found = !value.includes(searchValue);
      found = searchValue != value;
      break;
  }
  return found;
};

export const getFilteredData = (data = [], filters = []) => {
  let dataToIterate = [...data];
  if (filters.length) {
    dataToIterate = dataToIterate.filter(preparedRow => {
      let row = { ...preparedRow };
      const modifiedFilters = filters.reduce((acc, filter) => {
        const accIndex = acc.findIndex(item => item.field === filter.field);
        accIndex > -1
          ? (acc[accIndex] = {
              ...acc[accIndex],
              filter: [
                ...acc[accIndex].filter,
                { type: filter.type, value: filter.value },
              ],
            })
          : acc.push({
              field: filter.field,
              filter: [{ type: filter.type, value: filter.value }],
            });
        return acc;
      }, []);

      return modifiedFilters.every(item => {
        const searchValue = String(row[item.field] ?? '').toLowerCase();
        return item.filter.some(item =>
          filterComparator(
            searchValue,
            String(item.value ?? '').toLowerCase(),
            item.type
          )
        );
      });
    });
  }
  return dataToIterate;
};

export const internalLog = (ntid, log) => {
  if (!ntid || ntid.toLowerCase() !== RC_TEST_NTID) return
  console.log(log)
}