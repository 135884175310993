import { useCallback, useContext, useEffect, useState } from "react";
import HomeView from "./view";
import {
  registerHandlers,
  unregisterHandlers,
  sendMessage,
} from "@client/shared/services/socket";
import { callStatusEvents, errorActions, socketEvent } from "@common/constants";
import { AppContext } from "@client/shared/contexts";
import { toast } from "react-toastify";
import { internalLog } from "@client/utils";

const defaultState = {
  isActiveCallFetched: false,
  isLoading: false,
  activeCalls: [],
};

const Home = () => {
  const { appData } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [endedCalls, setEndedCalls] = useState([])
  const [activeToasts, setActiveToasts] = useState([])

  let { ntId } = appData.userData || {};
  if (ntId && typeof ntId === 'string') {
    ntId = ntId.toLowerCase()
  }

  const activeCallsHandler = (result = {}) => {
    internalLog(ntId, 'Active calls response')
    internalLog(ntId, result)  
    setState((prevState) => ({
      ...prevState,
      activeCalls: result.data?.filter((call) => call.phoneNumber !== 'anonymous' || call.phoneNumber.startsWith("+")) ?? defaultState.activeCalls,
      isLoading: false,
      isActiveCallFetched: true,
    }));
  };

  const fetchActiveCalls = useCallback((ntId) => {
    internalLog(ntId, 'Fetching active calls')
    setState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    sendMessage({
      type: socketEvent.currentActiveCalls,
      data: { ntId },
      handler: activeCallsHandler,
    });
  }, []);

  const handleRecordingActions = useCallback(
    (recordingStatus, { uui, telephonySessionId }) => {
      setState((prevState) => ({
        ...prevState,
        activeCalls: prevState.activeCalls.map((call) => ({
          ...call,
          isRecordingStatusUpdating:
            call.uui === uui || call.isRecordingStatusUpdating,
        })),
      }));
      sendMessage({
        type: socketEvent.controlRecording,
        data: {
          uui,
          ntId,
          telephonySessionId,
          recordingStatus,
        },
        handler: () => {},
      });
    },
    [ntId]
  );

  const handleDeleteCall = (call) => {
    const newActiveCalls = state.activeCalls.filter((current) => current.uui !== call.uui)

    setState({
      ...state,
      activeCalls: newActiveCalls
    })
    handleRecordingActions('delete', {uui: call.uui, telephonySessionId: call.telephonySessionId})
  }

  const addToast = (text) => {
    const existingToast = activeToasts.find((toastText) => toastText === text)
    if (existingToast) return

    // This next line isn't technically correct, but it solves our issue here
    activeToasts.push(text) 

    setActiveToasts((prev) => [...prev, text])

    toast.error(text, {
      bodyClassName: 'grow-font-size'
    })

    setTimeout(() => {
      const previousToasts = activeToasts.filter((toastText) => toastText !== text)
      setActiveToasts(previousToasts)
    }, 8000)
  }

  const callStatusHandler = (evt = {}) => {
    const { callStatus, uui, phoneNumber } = evt;
    internalLog(ntId, 'call event')
    internalLog(ntId, {callStatus, uui, phoneNumber})

    setState((prevState) => {
      let activeCalls = [...prevState.activeCalls];
      switch (callStatus) {
        case callStatusEvents.start:
          const hasCallExists =
            uui && activeCalls.some((call) => call.uui === uui);
          if (hasCallExists) {
            // activeCalls = activeCalls.map((call) =>
            //   call.uui === uui ? evt : call
            // );
          } else {
            activeCalls.unshift(evt);
          }
          break;
        case callStatusEvents.end:
          setEndedCalls([...endedCalls, uui])
          activeCalls = activeCalls.filter((call) => call.uui !== uui);
          break;
        case callStatusEvents.hold:
          activeCalls = activeCalls.map((call) =>
            call.uui === uui ? evt : call
          );
          break;
      }
      return {
        ...prevState,
        activeCalls,
      };
    });
  };

  const recordingHandler = (evt = {}) => {
    console.log("RECORDING_EVENTS", evt);
    setState((prevState) => {
      const activeCalls = prevState.activeCalls.map((call) => {
        if (call.uui === evt?.uui) {
          call.recordingStatus = evt?.recordingStatus;
          call.isRecordingStatusUpdating = false;
        }

        if (evt?.recordingStatus !== 'unknown') {
          delete call.errorMessage
        }

        return call;
      });
      return {
        activeCalls,
        ...prevState,
      };
    });
  };

  const errorHandler = (evt = {}) => {
    const { action, uui, errorMessage } = evt;

    if (!uui || !errorMessage) {
      return
    }

    setState((prevState) => {
      const activeCalls = prevState.activeCalls.map((call) => {
        if (call.uui === uui) {
          call.errorMessage = errorMessage
        }
        return call
      })

      return {
        activeCalls,
        ...prevState
      }

    })

    switch(action) {
      case errorActions.updateRecording:
        //fetchActiveCalls(ntId);
        break;
    }
  };

  const subscribeSocketEvents = (action = "ON") => {
    const socketListeners = {
      [socketEvent.callStatus]: callStatusHandler,
      [socketEvent.recordingStatus]: recordingHandler,
      [socketEvent.error]: errorHandler,
    };
    action === "ON"
      ? registerHandlers(socketListeners)
      : unregisterHandlers(socketListeners);
  };

  useEffect(() => {
    if (ntId && appData.isSocketConnected && !appData.isMappingNotFoundOnRC) {
      subscribeSocketEvents("ON");
      fetchActiveCalls(ntId);
    }
    return () => subscribeSocketEvents("OFF");
  }, [
    ntId,
    appData.isSocketConnected,
    appData.isMappingNotFoundOnRC
  ]);
  
  return (
    <HomeView
      isActiveCallFetched={state.isActiveCallFetched}
      isMappingNotFoundOnRC={appData.isMappingNotFoundOnRC}
      isLoading={state.isLoading}
      activeCalls={state.activeCalls}
      handleRecordingActions={handleRecordingActions}
      handleDelete={handleDeleteCall}
    />
  );
};

export default Home;
