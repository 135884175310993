import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import style from "./style";

const AboutModal = ({ version = {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <MenuItem onClick={() => setIsOpen(true)}>
        <ListItemText primary="About" />
        <ListItemIcon sx={style.icon}>
          <img src="/assets/info-light.svg" alt="Info" />
        </ListItemIcon>
      </MenuItem>
      <Dialog sx={style.dialog} open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle hasClose />
        <DialogContent>
          <Box
            sx={{
              alignItems: "start",
              paddingRight: 5,
            }}
          >
            <Typography
              variant="h6"
              sx={{ marginBottom: 4, fontWeight: "bold" }}
              color="primary"
            >
              Call Recording Manager
            </Typography>
            <Typography sx={{ marginBottom: 4 }} variant="subtitle2">
              Release {version.release}, Build {version.build}
            </Typography>
            <Typography sx={{ marginBottom: 4 }} variant="body2">
              &copy; 2023 RingCentral Inc. All Rights Reserved
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginRight: 4, marginBottom: 4 }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AboutModal;
