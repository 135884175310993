import { io } from "socket.io-client";
import config from "@client/config";

let socket = null;

export default function init() {
  if (socket) {
    if (socket.connected) {
      return;
    } else {
      socket.connect();
    }
  } else {
    console.log("Attempting socket connection");
    socket = io(config.socketBaseUrl, {
      withCredentials: true,
    });
  }

  socket.on("connect", () => {
    console.log("Connected socket");
    socket.emit("ping");
  });

  socket.on("connect_error", (error) => {
    if (error instanceof Error) {
      if (error.message === "Unauthorized") {
        // socket = null;
        console.log("Received Unauthorized event");
      }
    }
  });

  socket.on("pong", () => {
    console.log(
      "Received ping response from socket server. Connection established."
    );
  });

  socket.on("disconnect", () => {
    console.log("Socket disconnected");
  });
}

export function registerHandlers(eventHandlerMap = {}) {
  if (socket) {
    Object.keys(eventHandlerMap).forEach((event) => {
      socket.on(event, eventHandlerMap[event]);
    });
  }
}

export function unregisterHandlers(eventHandlerMap = {}) {
  if (socket) {
    Object.keys(eventHandlerMap).forEach((event) => {
      socket.off(event, eventHandlerMap[event]);
    });
  }
}

export function sendMessage({ type, data, handler }) {
  socket.emit(type, data, handler);
}
